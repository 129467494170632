import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import {
  InstantSearch,
  SearchBox,
  Hits,
  Highlight,
  Pagination,
  RefinementList,
  CurrentRefinements, InfiniteHits
} from 'react-instantsearch-dom';
import {instantMeiliSearch} from '@meilisearch/instant-meilisearch';
import {connectSortBy, connectStats} from 'react-instantsearch-dom';
import qs from 'qs';

const SortBy = ({items, refine, currentRefinement}) => {
  const isSameAttribute = (val1, val2) => val1.split(':')[1] === val2.split(':')[1]
  const isRefined = (value) => isSameAttribute(value, currentRefinement)
  const isAsc = () => !!currentRefinement.includes(':asc')

  const doRefine = (event, value) => {
    event.preventDefault();
    if (isSameAttribute(value, currentRefinement)) {
      refine(currentRefinement.includes(':asc') ? value.replace(':asc', ':desc') : value)
      return;
    }
    refine(value)
  }
  return <div>
    {items.map((item, index) => (
      <span key={item.value}>
      <span
        className={`${isRefined(item.value) ? 'font-bold text-teal-400' : ''} cursor-pointer`}
        onClick={event => doRefine(event, item.value)}
      >{isRefined(item.value) ? isAsc() ? '↧ ' : '↥ ' : ''}{item.label}</span>
        {index < items.length - 1 ? ' | ' : ''}
      </span>
    ))}
  </div>
}
const CustomSortBy = connectSortBy(SortBy);

const Stats = ({nbHits}) => <div>{nbHits} results found</div>
const CustomStats = connectStats(Stats);

const searchClient = instantMeiliSearch(window.meilisearchHost, '', {
  keepZeroFacets: true,
  primaryKey: 'key'
});

const mapItemType = {
  "attachment": 'Attachment',
  "blogPost": 'Blog Post',
  "book": 'Book',
  "bookSection": 'Book Section',
  "conferencePaper": 'Conference Paper',
  "document": 'Document',
  "forumPost": 'Forum Post',
  "journalArticle": 'Journal Article',
  "magazineArticle": 'Magazine Article',
  "newspaperArticle": 'Newspaper Article',
  "presentation": 'Presentation',
  "report": 'Report',
  "thesis": 'Thesis',
  "videoRecording": 'Video Recording',
  "webpage": 'Webpage'
}
const displayCollectionLabels = (value) => value.map(i => window.collections[i]?.label ?? 'N/A').join(', ')
const displayFields = [
  {label: 'Author', key: 'authors', displayUsing: (value) => value.join(', ')},
  {label: 'Abstract', key: 'abstract'},
  {label: 'Date', key: 'date'},
  {label: 'Institution', key: 'institution'},
  {label: 'Publication', key: 'publication'},
  {label: 'Report type', key: 'reportType'},
  {label: 'Avenues to protection', key: 'avenues_to_protection', displayUsing: displayCollectionLabels},
  {label: 'Region', key: 'region', displayUsing: displayCollectionLabels},
  {label: 'Tags', key: 'tags', displayUsing: (value) => value.join(', ')},
  {label: 'Actors and programs', key: 'actors_and_programs', displayUsing: displayCollectionLabels},
  {label: 'Topic', key: 'topic', displayUsing: displayCollectionLabels},
  {label: 'Country', key: 'country', displayUsing: displayCollectionLabels},
]

const filterLabels = {
  'itemType': 'Item Type',
  'tags': 'Tags',
  'region': 'Region',
  'reportType': 'Report Type',
  'avenues_to_protection': 'Avenues to protection',
  'country': 'Country',
  'topic': 'Topic',
  'actors_and_programs': 'Actors and programs',
}
const collectionKeyToLabel = item => ({
  ...item,
  label: window.collections[item.label]?.label || 'N/A',
})
const Hit = ({hit}) => {
  const [showMore, setShowMore] = useState(false);
  const displayField = (field, hit) => <li>
    <span className='font-bold mr-1'>{field.label}:</span>
    {
      field.html ?
        <span
          className='inline-block'
          dangerouslySetInnerHTML={{__html: field.displayUsing ? field.displayUsing(hit[field.key]) : hit[field.key]}}>
              </span>
        :
        <span className='inline-block'>
                {field.displayUsing ? field.displayUsing(hit[field.key]) : hit[field.key]}
              </span>

    }
  </li>
  const arrow = !showMore ? '&darr;' : '&uarr;';

  return (<div className="mb-3 hover:bg-gray-100 px-2 py-3" key={hit.key}>
    <span className="italic font-semibold text-sm">{mapItemType[hit.itemType]}</span>
    <div
      onClick={() => setShowMore(!showMore)}
      className="cursor-pointer text-left text-xl font-bold block mb-3">
      <Highlight attribute="title" hit={hit}/>
    </div>
    <a href={hit.link} target='_blank' className='text-teal-400 font-bold mb-3 block underline break-all'>{hit.link}</a>
    <ul className='border-b border-black pb-4 relative cursor-pointer' onClick={() => setShowMore(!showMore)}>
      <span className='absolute right-0 bottom-0 text-2xl' dangerouslySetInnerHTML={{__html: arrow}}/>
      {!showMore ?
        displayFields.filter(f => ['authors', 'date', 'tags'].includes(f.key))
          .map(field => (!hit[field.key] || (field.displayUsing && !field.displayUsing(hit[field.key]))) ? <></> :
            displayField(field, hit))
        :
        displayFields.map(field => (!hit[field.key] || (field.displayUsing && !field.displayUsing(hit[field.key]))) ? <></> :
          displayField(field, hit))
      }
    </ul>
  </div>)
}


const App = () => {
  const [showFilters, setShowFilters] = useState(false)
  const urlToSearchState = location => qs.parse(location.search.slice(1));
  const DEBOUNCE_TIME = 300;
  const createURL = state => `?${qs.stringify(state)}`;

  const searchStateToUrl = (searchState) =>
    searchState ? `${window.location.origin}${createURL(searchState)}` : '';

  const sortItems = [
    {value: 'items-2509047:clean_title:asc', label: 'Title'},
    {value: 'items-2509047:inline_authors:asc', label: 'Author'},
    {value: 'items-2509047:date:asc', label: 'Date'},
  ]
  const location = window.location
  const [searchState, setSearchState] = useState(urlToSearchState(location))
  let debouncedSetState;
  const onSearchStateChange = searchState => {
    clearTimeout(debouncedSetState);
    const filteredSearchState = {...searchState}
    delete filteredSearchState.page
    debouncedSetState = setTimeout(() => {
      window.history.replaceState({}, window.title,
        searchStateToUrl(filteredSearchState), filteredSearchState);
    }, DEBOUNCE_TIME);
    setSearchState(searchState);
  };
  useEffect(() => {
    if (location.state?.query) return
    setSearchState(urlToSearchState(location))
    window.addEventListener('orientationchange', () => setShowFilters(false))
  }, [])
  const transformCurrentRefinements = (items) => {
    return items.filter(f => f.attribute).map(filter => {
      filter.label = `${filterLabels[filter.attribute]}: `

      if (filter.attribute === 'itemType') {
        filter.items = filter.items.map(item => ({...item, label: mapItemType[item.label]}))
      }

      if (['region', 'country', 'topic', 'avenues_to_protection', 'actors_and_programs'].includes(filter.attribute)) {
        filter.items = filter.items.map(item => ({...item, label: window.collections[item.label]?.label || 'N/A'}))
      }

      return filter
    })
  }
  return (<>
      <InstantSearch searchClient={searchClient}
                     searchState={searchState}
                     onSearchStateChange={onSearchStateChange}
                     indexName='items-2509047'>
        <div className="bg-gray-800 text-white">
          <div className="container mx-auto flex py-10 px-4 flex-col md:flex-row">
            <div className="mb-10 md:mb-0 md:w-1/3">
              <h2 className='text-xl font-bold mb-4'>↦ FILTERED BY</h2>
              <CurrentRefinements
                className='font-bold text-teal-400'
                clearsQuery={true}
                transformItems={transformCurrentRefinements}
              />
            </div>
            <div className="flex-1">
              <h2 className='text-xl font-bold mb-4'>↦ SEARCH</h2>
              <SearchBox translations={{placeholder: 'Text search by item type, country, year or other identifier'}}
                         className='d-inline-block text-black mb-6'
              />
              <div className='flex flex-col sm:flex-row sm:space-y-0 space-y-4'>
                <div>
                  <h2 className='text-xl font-bold'>↦ SHOWING</h2>
                  <CustomStats/>
                </div>
                <div className='sm:ml-16'>
                  <h2 className='text-xl font-bold'>↦ SORT BY</h2>
                  <CustomSortBy defaultRefinement={sortItems[0].value} items={sortItems}/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='container px-4 pt-4 pb-2 md:hidden'>
          <button onClick={() => setShowFilters(!showFilters)}>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"/>
            </svg>
          </button>
        </div>
        <div className='md:pt-12 pb-12 relative'>
          { showFilters ? <div className={`w-full h-full absolute bg-black opacity-50 z-10`}></div> : <></>}
          <div className="container mx-auto flex">
            <div
              className={`${ showFilters ? 'block' : 'hidden' } absolute md:block bg-white filter-wrapper flex-shrink-0 md:pr-0 md:static md:w-1/3 pr-4 w-full z-20`}>
              <div className="md:w-2/3 px-4">
                <h2 className='text-xl font-bold uppercase mb-2'>↦ Browse</h2>
                <div className='border-b border-black mb-6'>
                  <h3 className='text-lg font-bold mb-2'>ITEM TYPE</h3>
                  <RefinementList showMore
                                  limit={5}
                                  showMoreLimit={900}
                                  transformItems={items =>
                                    items.map(item => ({
                                      ...item,
                                      label: mapItemType[item.label],
                                    }))}
                                  attribute="itemType"/>
                </div>
                <div className='border-b border-black mb-6'>
                  <h3 className='text-lg font-bold mb-2'>TAGS</h3>
                  <RefinementList  showMore
                                  limit={5}
                                  transformItems={items => _.sortBy(items, 'label')}
                                  showMoreLimit={900}
                                  attribute="tags"/>
                </div>
                <div className='border-b border-black mb-6'>
                  <h3 className='text-lg font-bold mb-2'>REGION</h3>
                  <RefinementList
                    showMore
                    showMoreLimit={100}
                    limit={5}
                    transformItems={items =>
                      items.map(collectionKeyToLabel)}
                    attribute="region"/>
                </div>
                <div className='border-b border-black mb-6'>
                  <h3 className='text-lg font-bold mb-2'>REPORT TYPE</h3>
                  <RefinementList
                    showMore
                    showMoreLimit={100}
                    limit={5}
                    attribute="reportType"/>
                </div>
                <div className='border-b border-black mb-6'>
                  <h3 className='text-lg font-bold mb-2'>AVENUES TO PROTECTION</h3>
                  <RefinementList
                    showMore
                    limit={5}
                    showMoreLimit={100}
                    transformItems={items =>
                      items.map(collectionKeyToLabel)}
                    attribute="avenues_to_protection"/>
                </div>
                <div className='border-b border-black mb-6'>
                  <h3 className='text-lg font-bold mb-2'>COUNTRY</h3>
                  <RefinementList
                    showMore
                    limit={5}
                    showMoreLimit={100}
                    transformItems={items =>
                      items.map(collectionKeyToLabel)}
                    attribute="country"/>
                </div>
                <div className='border-b border-black mb-6'>
                  <h3 className='text-lg font-bold mb-2'>TOPIC</h3>
                  <RefinementList
                    showMore
                    limit={5}
                    showMoreLimit={100}
                    transformItems={items =>
                      items.map(collectionKeyToLabel)}
                    attribute="topic"/>
                </div>
                <div className='border-b border-black mb-6'>
                  <h3 className='text-lg font-bold mb-2'>ACTORS AND PROGRAMS</h3>
                  <RefinementList
                    showMore
                    limit={5}
                    showMoreLimit={100}
                    transformItems={items =>
                      items.map(collectionKeyToLabel)}
                    attribute="actors_and_programs"/>
                </div>
              </div>
            </div>
            <div className="flex-1 overflow-x-hidden px-4">
              <InfiniteHits hitComponent={Hit}/>
            </div>
          </div>
        </div>
      </InstantSearch>
    </>
  )
    ;
};

export default App;

if (document.getElementById('app')) {
  ReactDOM.render(<App/>, document.getElementById('app'));
}
